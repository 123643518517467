import request from "../../../common/utils/request";

// 获取项目库信息
export function getProjects(params) {
  return request({
    url: "/itf/common/cjrh/equipment/getProjects.json",
    method: "get",
    params:params,
  });
}


// 获取项目库信息
export function getProjectById(params) {
  return request({
    url: "/itf/common/cjrh/equipment/getProjectById.json",
    method: "get",
    params:params,
  });
}


