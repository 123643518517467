<template>
  <div class="ProjectDetail">
    <ContainerCard :locationMsg="locationMsg" :width="61">
      <div class="info">
        <div class="infoTop">
          <div class="intros">
            <h2 class="name">{{ infoData.project_name }}</h2>
            <!-- <ul class="labelGroup" >
              <li class="item" v-for="(item,index) in infoData.techList" :key="index">{{item}}</li>
            </ul> -->
            <!-- <div class="intro">
              <span>项目联系人</span><span>{{ infoData.contact }}</span>
              <p>
                建设主体<span style="color: #404040">{{
                  infoData.enterprise_name
                }}</span>
              </p>
            </div> -->
          </div>
          <el-image class="logo" v-if="infoData.photo" :src="httpConfig.baseURL+infoData.photo" alt="" />
          <div class="no-imgbox" v-if="!infoData.photo">
              <el-image class="no-img" :src=" defaultImg " alt="" />
          </div>
        </div>
        <div class="lineBox"></div>
        <div class="infomain">
        <div class="info-title"  >
              <img src="../../assets/images/信息资讯.png" alt="" />
              <span class="label">技术领域</span>
              <div class="label-value" >
                <span v-for="(item, index) in infoData.techList" :key="index" class="label-item">{{ item }}</span>
              </div>
        </div>
        </div>

        <div class="lineBox"></div>
        <div class="infomain">
          <div class="info-title">
            <img src="../../assets/images/信息资讯.png" alt="" />
            <span class="label">项目描述</span>
            <p class="artContent" style="line-height: 20px">{{infoData.memo}}</p>
          </div>
        </div>
        <div class="lineBox"></div>
        <Contact :infoData="infoData"></Contact>
      </div>
    </ContainerCard>
  </div>
</template>
<script>
import Location from "../../components/Location.vue";
import Consult from "../../components/Consult.vue";
import ContainerCard from "../../components/newContainerCard.vue";
import { getProjectById } from "../../api/SixLibraries/Project/Project.js";
import { objDeepCopy } from "../../common/utils/tool";
import Contact from "../../components/SixLibraries/Contact.vue";
import { mapGetters } from "vuex";
import { getPlainText } from "../../common/utils/tool";
import httpConfig from "../../common/utils/config";
import defaultImg from "../../assets/images/暂无数据默认图片.png";
export default {
  computed: {
    ...mapGetters(["techfieldList"]),
  },
  data() {
    return {
      defaultImg,
      httpConfig,
      infoData: {},
      type: [
        {
          label: "全部",
          value: "all",
        },
        { label: "国家级", value: "1" },
        { label: "省级", value: "2" },
        { label: "市级", value: "3" },
        { label: "县级", value: "4" },
        { label: "校级", value: "5" },
        { label: "其他", value: "6" },
      ],
      locationMsg: [
        { name: "七大库" },
        { name: "项目库", router: "Project" },
        { name: "项目详情", router: "Project/ProjectDetail" },
      ],
    };
  },
  components: { Contact, ContainerCard, Location, Consult },
  created() {
    let id = this.$route.query.id;
    this.getData(id);
  },
  methods: {
    async getData(id) {
      let that = this;
      let res = await getProjectById({ id: id });
      let tmp = objDeepCopy(res.data);
      const regex = new RegExp("<img", "gi");
      tmp.memo =
        res.data?.memo &&
        res.data?.memo.replace(regex, '<img style="max-width: 100%;"');
      tmp.techList = [];

      Object.keys(that.techfieldList).forEach(function (key) {
        if (
          tmp?.tech_field &&
          tmp?.tech_field.split(",").indexOf(that.techfieldList[key]) > -1
        ) {
          tmp.techList.push(key);
        }
      });
      tmp.memo = getPlainText(getPlainText(tmp.memo));
      this.infoData = tmp;
      console.log("项目库的详情数据是:",this.infoData)
    },
  },
};
</script>
<style scoped lang="scss">
.info {
  width: 756px;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 24px;
  .infoTop {
    width: 756px;
    // height: 158px;
    display: flex;
    justify-content: space-between;
    margin: 35px 0px 15px 0px;
  }
  .logo {
    width: 184px;
    height: 110px;
    border-radius: 6px;
  }
  .no-imgbox {
        width: 150px;
        height: 90px;
        background: #F1F1F1;
        border-radius: 6px;
        text-align: center;
        line-height: 90px;
        .no-img {
        width: 24px;
        height: 24px;
      }
      }
  .intros {
    display: flex;
    flex-direction: column;
    /* width: 70%; */
    .name {
      font-size: 24px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: bold;
      color: #101010;
      line-height: 36px;
    }
    .labelGroup {
      margin-top: 20px;
      width: 100%;
      display: flex;
      .item {
        margin-right: 8px;
        list-style: none;
        border-radius: 8px;
        border: 1px solid #3e9cff;
        padding: 7px 10px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #3e9cff;
      }
    }
    .intro {
      width: 100%;
      margin-top: 12px;
      > p {
        margin-top: 20px;
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #8896a7;
        span {
          padding-left: 12px;
        }
      }
      span:nth-child(1) {
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #8896a7;
      }
      span:nth-child(2) {
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #404040;
        padding-left: 12px;
      }
    }
  }
  .lineBox {
    // margin-top: 24px;
    width: 100%;
    height: 1px;
    background: #d8d8d8;
  }
  .infomain {
    margin-top: 24px;
    .info-title {
      margin-bottom: 24px;
      img {
        width: 8px;
        height: 10px;
        margin-right: 12px;
      }
      .label {
        font-size: 16px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #101010;
        line-height: 24px;
      }
      .label-value{  
          display: flex;
          flex-direction: row;
          margin-top: 23px;
          .label-item{
            // width: 88px;
            height: 34px;
            padding: 0 16px;
            background: #FAFAFA;
            border-radius: 18px;
            border: 1px solid #F1F1F1;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #101010;
            text-align: center;
            line-height: 34px;
            margin-right: 12px;
          }
        }
    }
    .title {
      font-size: 28px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #101010;
    }
    .artContent {
      margin-top: 20px;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #404040;
      line-height: 36px;
      text-align: justify;
    }
  }
}
::v-deep .locationList{
  position: absolute;
  left: -27px;
}

@media screen and (max-width: 768px) {
}
</style>